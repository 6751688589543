@font-face {
  font-family: "Alphakind";
  src: local("Alphakind"), url("./assets/fonts/Alphakind.ttf.woff") format("truetype");
}

@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Nunito&display=swap");

.App {
  /* width: 100vw; */
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background: #0b0c22;
}

#tsparticles {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  z-index: -1;
}

.Countdown {
  /* margin: 10px auto; */
  /* padding-bottom: 20px; */
  font-family: "Nunito";
}

.Countdown-col {
  display: inline-block;
}

.Countdown-col-element {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Countdown-col-element strong {
  font-size: 30px;
}

.ak {
  font-family: "Alphakind", "cursive";
}

.nunito {
  font-family: "Nunito", sans-serif;
}

.bubble {
  font-family: "Bubblegum Sans", cursive;
}

.loadbar {
  width: 8px;
  height: 400px;
  background-color: #684ce2;
  border-radius: 12px;
  position: relative;
  transform: rotate(180deg);
}

.bar {
  width: 100%;
  display: block;
  font-size: 12px;
  background-color: #fff;
  color: #fff;
  position: absolute;
  top: 0;
  border-radius: 0px 0px 12px 12px;
}

.percent {
  font-size: 12px;
  color: #fff;
  position: absolute;
  right: 20px;
}

.bar-closed {
  width: 100%;
  display: block;
  font-family: arial;
  font-size: 12px;
  background-color: #684ce2;
  color: #fff;
  position: absolute;
  top: 0;
  border-radius: 0px 0px 12px 12px;
}

.circle {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  background-color: #fff;
  transform: translate(-16px, -20px);
  box-shadow: 0px 0px 20px #fff;
  z-index: 2;
}

.circle-closed {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  background-color: #684ce2;
  transform: translate(-16px, -20px);
  box-shadow: 0px 0px 20px #684ce2;
  z-index: 2;
}

.rotate {
  /* animation: light 10s ease-in infinite; */
}

@keyframes light {
  0% {
    filter: drop-shadow(0px 0px 30px #ffffff);
  }
  50% {
    filter: drop-shadow(0px 0px 3px #ffffff);
  }
  100% {
    filter: drop-shadow(0px 0px 30px #ffffff);
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-background-clip: content-box !important;
  background-clip: content-box !important;
  -webkit-text-fill-color: #ffffff;
  -webkit-box-shadow: 0 0 0 1000px #171535 inset;
  box-shadow: 0 0 0 1000px #171535 inset;
  background-color: #171535 !important;
  
}